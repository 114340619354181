<template>
  <!-- Page Content  -->
  <b-container fluid>
    <b-row>
      <!-- BEGIN:Body -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Laporan Kerjasama Per Jenis Kerjasama</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <!-- BEGIN:Petunjuk -->
              <iq-card
                class="
                  iq-card-block iq-bg-info
                  text-dark
                  iq-card-stretch iq-card-height
                  p-3
                "
              >
                <div>
                  <span class="font-weight-bold"
                    >PETUNJUK PENGGUNAAN FASILITAS LAPORAN KERJASAMA PER JENIS
                    KERJASAMA :</span
                  >
                  <div>
                    1. Untuk mencari data laporan kerjasama per jenis kerjasama
                    semua tahun :
                    <br />
                    <p class="px-3">
                      - Pilih "Jenis Kerjasama" <br />
                      - Klik checkbox "Semua Tahun" <br />
                      - Klik tombol "Cari" Untuk melihat data laporan kerjasama
                    </p>
                  </div>
                  <div>
                    2. Untuk mencari data laporan kerjasama per jenis kerjasama
                    per tahun :
                    <br />
                    <p class="px-3">
                      - Pilih "Jenis Kerjasama" <br />
                      - Masukan "Tahun" yang ingin dicari <br />
                      - Klik tombol "Cari" Untuk melihat data laporan kerjasama
                    </p>
                  </div>
                </div>
              </iq-card>
              <!-- ./END:Petunjuk -->
              <!-- BEGIN:Form Pencarian -->
              <div
                class="
                  card
                  iq-card-block
                  bg-light
                  text-dark
                  iq-card-stretch iq-card-height
                "
              >
                <div class="card-header bg-primary text-center">
                  <h5 class="font-weight-bold text-white">Form Pencarian</h5>
                </div>
                <div class="card-body">
                  <b-form class="px-4">
                    <b-form-group>
                      <input-datalists
                        id="jenisKs"
                        class="w-full text-sm"
                        placeholder="Pilih Jenis Kerjasama"
                        v-model="form.jenisKs"
                        label="dataJenisKs"
                        required
                        :options="
                          (listDataKs &&
                            listDataKs.map(data => {
                              return {
                                dataJenisKs:
                                  data.kodeJenisPks + ` | ` + data.namaPks,
                                kodeJenisKs: data.kodeJenisPks,
                                idJenisKs: parseInt(data.kodeJenisPks)
                              }
                            })) ||
                            []
                        "
                        :clearable="false"
                      >
                        <template #header>
                          <label class="block">Jenis Kerjasama:</label>
                        </template>
                      </input-datalists>
                    </b-form-group>
                    <div>Tahun:</div>
                    <b-row>
                      <b-col class="mt-3" lg="2">
                        <b-form-group>
                          <b-form-checkbox
                            id="semuaTahun"
                            v-model="form.semuaTahun"
                            required
                            @click="setSemuaTahun($event)"
                            @change="form.tahun = null"
                            :disabled="form.tahun != null"
                            >Semua Tahun</b-form-checkbox
                          >
                        </b-form-group>
                      </b-col>
                      <b-col class="py-3" lg="1">
                        <div class="font-weight-bold">atau</div>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <input-primary
                            id="tahun"
                            v-model="form.tahun"
                            placeholder="Masukkan tahun yang ingin dicari"
                            required
                            type="number"
                            max-length="4"
                            :disabled="form.semuaTahun == 1"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col>
                        <b-btn
                          class="btn-block"
                          variant="primary"
                          @click="searchData"
                          :disabled="!isValidForm"
                          >Cari</b-btn
                        >
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
              </div>
              <!-- ./END:Form Pencarian -->
              <!-- BEGIN:Table -->
              <div class="card mt-4">
                <div class="card-header">
                  <h5 class="font-weight-bold">
                    Daftar Laporan Kerjasama Per Jenis Kerjasama
                  </h5>
                </div>
                <div class="card-body">
                  <div class="align-items-center">
                    <b-row>
                      <b-col>
                        <b-form-input
                          class="w-25"
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Cari Data"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-table
                      responsive
                      bordered
                      :fields="thead"
                      :items="listData"
                      class="mt-2"
                      :busy="isLoading"
                      :filter="filter"
                      :per-page="perPage"
                      :current-page="currentPage"
                      @filtered="onFiltered"
                      show-empty
                    >
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template #empty>
                        <div
                          class="
                            bg-light
                            text-danger
                            font-weight-bold
                            mx-auto
                            text-center
                          "
                        >
                          Data tidak ditemukan
                        </div>
                      </template>
                    </b-table>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Per page:"
                          label-for="per-page-select"
                          label-size="sm"
                          class="d-flex"
                        >
                          <b-form-select
                            class="ml-2"
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-pagination
                          class="float-right"
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div class="card-footer">
                  <b-row>
                    <b-col>
                      <b-btn class="btn-block" variant="danger"
                        >Unduh Excel XLS</b-btn
                      >
                    </b-col>
                    <b-col>
                      <b-btn class="btn-block" variant="secondary"
                        >Unduh Excel XLSX</b-btn
                      >
                    </b-col>
                    <b-col>
                      <b-btn class="btn-block" variant="success"
                        >Unduh CSV</b-btn
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- ./END:Table -->
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Body -->
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { Vue } from 'vue-property-decorator'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'laporan.perJenisKerjasama',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status =
        (this.form.semuaTahun || this.form.tahun) && this.form.jenisKs !== null
      return status
    }
  },
  async mounted() {
    this.innitData()
    /**
     * Set the initial number of items
     */
    this.totalRows = this.listData.length
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Set checkbox semua tahun
     */
    setSemuaTahun(event) {
      event.target.checked === true
        ? (this.form.semuaTahun = 1)
        : (this.form.semuaTahun = 0)
    },
    /**
     * Get data kategori PKS
     */
    async getDataKategoriPks_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListMasterPks?type=jenisPks`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataKs = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Search Data
     */
    searchData() {
      this.$toast.info('Sedang dalam pengembangan')
    },
    /**
     * Init Data
     */
    async innitData() {
      /**
       * Get List Data
       */
      await Promise.all([this.getDataKategoriPks_()])
    }
  },
  data() {
    return {
      // Tabel Options
      isLoading: false,
      filter: null,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: '100' }],
      currentPage: 1,
      totalRows: 1,
      // Form
      form: {
        semuaTahun: null,
        tahun: null,
        jenisKs: null
      },
      // Data KS
      listDataKs: [],
      // Table
      thead: [
        { label: 'No', key: 'id', class: 'text-center' },
        { label: 'Kode KS', key: 'kode_ks', class: 'text-center' },
        { label: 'Kode Jenis KS', key: 'kode_jenis_ks', class: 'text-center' },
        { label: 'Nomor PKS UT', key: 'nomor_pks_ut', class: 'text-center' },
        { label: 'Keterangan', key: 'keterangan', class: 'text-justify w-50' }
      ],
      listData: [
        {
          id: 1,
          kode_ks: '000004',
          kode_jenis_ks: '002',
          nomor_pks_ut: '5990/UN31/KS/2012',
          keterangan: 'Sewa Lahan/Tanah untuk Gedung TK Ananda'
        }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    const idUnitKs = store.state.authService.idUnitKs
    if (MENU_ROLE.DAAK_DAN_KEUANGAN.includes(String(idUnitKs))) {
      return next()
    } else {
      Vue.$toast.error('Anda tidak memiliki hak akses')
      return next({ name: 'sidebar.dashboard' })
    }
  }
}
</script>
